import QRCode from 'qrcode.react';
import React, { Component } from 'react';
import './QRCode2.css'

// TODO: live update demo
export default class QRCode2 extends Component {
    state = {
        value: 'https://www.outedg.com.br/',
        size: 128,
        fgColor: '#000000',
        bgColor: '#ffffff',
        level: 'L',
        renderAs: 'svg',
        includeMargin: false,
        includeImage: true,
        imageH: 24,
        imageW: 24,
        imageX: 0,
        imageY: 0,
        imageSrc: 'https://www.outedg.com.br/ativos/favicon.png',
        imageExcavate: true,
        centerImage: true,
    };

    render() {
        var imageSettingsCode = this.state.includeImage
            ? `
  imageSettings={{
    src: "${this.state.imageSrc}",
    x: ${this.state.centerImage ? 'null' : this.state.imageX},
    y: ${this.state.centerImage ? 'null' : this.state.imageY},
    height: ${this.state.imageH},
    width: ${this.state.imageW},
    excavate: ${this.state.imageExcavate},
  }}`
            : '';
        var code = `<QRCode
  value={"${this.state.value}"}
  size={${this.state.size}}
  bgColor={"${this.state.bgColor}"}
  fgColor={"${this.state.fgColor}"}
  level={"${this.state.level}"}
  includeMargin={${this.state.includeMargin}}
  renderAs={"${this.state.renderAs}"}${imageSettingsCode}
/>`;
        return (
            <div class="row py-5 px-4">
                <div class="col-xl-4 col-md-6 col-sm-10 mx-auto">
                    <div class="bg-white shadow rounded overflow-hidden">

                        <div className="px-4 pt-0 pb-4 bg-dark">
                            <div class="media align-items-end profile-header">
                                <div class="profile mr-3">
                                    <div class="rounded mb-2 img-thumbnail" width="130">
                                        <QRCode
                                            value={this.state.value}
                                            size={this.state.size}
                                            fgColor={this.state.fgColor}
                                            bgColor={this.state.bgColor}
                                            level={this.state.level}
                                            renderAs={this.state.renderAs}
                                            includeMargin={this.state.includeMargin}
                                            imageSettings={
                                                this.state.includeImage
                                                    ? {
                                                        src: this.state.imageSrc,
                                                        height: this.state.imageH,
                                                        width: this.state.imageW,
                                                        x: this.state.centerImage ? null : this.state.imageX,
                                                        y: this.state.centerImage ? null : this.state.imageY,
                                                        excavate: this.state.imageExcavate,
                                                    }
                                                    : null
                                            }
                                        />
                                        {/* <a href="#" class="btn btn-dark btn-sm btn-block">Edit profile</a> */}
                                    </div>
                                </div>
                                 <div class="media-body mb-5 text-white">
                                    <h4 class="mt-0 mb-0">QRCode grátis</h4>
                                    <p class="small mb-4"> <i class="fa fa-calendar mr-2"></i>Sem vencimento</p>
                                </div> *
                            </div>
                        </div>

                        <div className="bg-light p-4 d-flex justify-content-end text-center">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <h5 class="font-weight-bold mb-0 d-block">241</h5><small class="text-muted"> <i class="fa fa-picture-o mr-1"></i>Photos</small>
                                </li>
                                <li class="list-inline-item">
                                    <h5 class="font-weight-bold mb-0 d-block">84K</h5><small class="text-muted"> <i class="fa fa-user-circle-o mr-1"></i>Followers</small>
                                </li>
                            </ul>
                        </div>
                        <div className="py-4 px-4">

                            <div className="form-group ">
                                <label>Size(px):</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    onChange={(e) =>
                                        this.setState({ size: parseInt(e.target.value, 10) || 0 })
                                    }
                                    value={this.state.size}
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label> Background Color:</label>
                                    <input
                                        className="form-control"
                                        type="color"
                                        onChange={(e) => this.setState({ bgColor: e.target.value })}
                                        value={this.state.bgColor}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Foreground Color:</label>
                                    <input
                                        className="form-control"
                                        type="color"
                                        onChange={(e) => this.setState({ fgColor: e.target.value })}
                                        value={this.state.fgColor}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label>Error Level:</label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.setState({ level: e.target.value })}
                                        value={this.state.level}>
                                        <option value="L">L</option>
                                        <option value="M">M</option>
                                        <option value="Q">Q</option>
                                        <option value="H">H</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Render As:</label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.setState({ renderAs: e.target.value })}
                                        value={this.state.renderAs}>
                                        <option value="svg">SVG</option>
                                        <option value="canvas">Canvas</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.includeMargin}
                                        onChange={(e) => this.setState({ includeMargin: e.target.checked })}
                                    />
                                    <label className="form-check-label">Margem</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Endereço da web Uri/Url):</label>
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    cols="80"
                                    onChange={(e) => this.setState({ value: e.target.value })}
                                    value={this.state.value}
                                />
                            </div>
                            <div className="form-group">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.includeImage}
                                        onChange={(e) => this.setState({ includeImage: e.target.checked })}
                                    />
                                    <label className="form-check-label">Incluir Imagem</label>
                                </div>
                            </div>

                            <fieldset className="form-group" disabled={!this.state.includeImage}>
                                <div className="form-group">
                                    <label>Icone:</label>
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        cols="80"
                                        onChange={(e) => this.setState({ imageSrc: e.target.value })}
                                        value={this.state.imageSrc}
                                    />
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Image Width: {this.state.imageW}</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={this.state.imageW}
                                            onChange={(e) =>
                                                this.setState({ imageW: parseInt(e.target.value, 10) })
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Image Height: {this.state.imageH}</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={this.state.imageH}
                                            onChange={(e) =>
                                                this.setState({ imageH: parseInt(e.target.value, 10) })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.state.centerImage}
                                            onChange={(e) => this.setState({ centerImage: e.target.checked })}
                                        />
                                        <label>Centralizar imagem</label>
                                    </div>
                                </div>
                                <fieldset disabled={this.state.centerImage}>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label for="xrange" >Image X: {this.state.imageX}</label>
                                            <input
                                                id="x-range"
                                                className="form-control-range"
                                                type="range"
                                                min={0}
                                                max={this.state.size - this.state.imageW}
                                                value={this.state.imageX}
                                                onChange={(e) =>
                                                    this.setState({ imageX: parseInt(e.target.value, 10) })
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label for="y-range">Image Y: {this.state.imageY}</label>
                                            <input
                                                id="yrange"
                                                className="form-control-range"
                                                type="range"
                                                min={0}
                                                max={this.state.size - this.state.imageH}
                                                value={this.state.imageY}
                                                onChange={(e) =>
                                                    this.setState({ imageY: parseInt(e.target.value, 10) })
                                                }
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={this.state.imageExcavate}
                                        onChange={(e) =>
                                            this.setState({ imageExcavate: e.target.checked })
                                        }
                                    />
                                    <label>Escavar</label>
                                </div>
                            </fieldset>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
